<template>
  <div>
    <v-container>
      <SettingsGear :showIcon="true" />
      <div style="height: 15vh">
        <div
          style="
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
            margin-top: 2vh;
          "
        >
          <img src="@/assets/logos/dime_logo_gold.png" style="height: 7em" />
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: flex-end;
          "
        >
          <!-- <img
              src="@/assets/logos/dime_thinkhigher.png"
              style="height: 2.5em"
            /> -->
        </div>
      </div>
      <div style="height: 66vh; overflow: hidden">
        <div style="margin-top: 1vh"></div>
        <div class="content-title">FAQS & Troubleshooting</div>
        <div class="faq-return text-uppercase" @click="onClickReturn">
          <img class="faq-return-left" src="@/assets/ui/left.png" />
          <span class="faq-return-right text-white">RETURN</span>
        </div>
        <!-- <div class="faq-title text-center">{{ faqItem.title }}</div> -->
        <div class="faq-content">
          <!-- <img src="@/assets/bg/placeholder.png" width="370vh" height="200vh" /> -->
          <van-swipe
            class="my-swipe"
            :autoplay="5000"
            indicator-color="white"
            :show-indicators="false"
            :stop-propagation="false"
            :touchable="false"
            @change="onChange"
          >
            <van-swipe-item
              v-for="(item, index) of faqItem.imgList"
              :key="index"
            >
              <img :src="item" width="100%" height="100%" />
            </van-swipe-item>
          </van-swipe>
          <div class="faq-title">
            {{ faqItem.title }}
          </div>
          <div class="indicator">
            <div
              class="indicator-item"
              :style="
                index == selectIndex
                  ? 'background-color:#ffab00;'
                  : 'background-color: white;'
              "
              v-for="(item, index) of faqItem.imgList"
              :key="index"
            ></div>
          </div>
          <div class="faq-msg">
            {{ faqItem.content }}
          </div>
        </div>
      </div>
      <Footer />
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Vue from "vue";

export default {
  name: "faqDetails",
  data() {
    return {
      faqItem: null,
      selectIndex: 0, //轮播图下标
    };
  },
  created() {
    this.faqItem = this.$route.query.item;
  },
  methods: {
    onClickReturn() {
      this.$router.go(-1); //返回上一层
    },
    onChange(index) {
      this.selectIndex = index;
    },
  },
  computed: {
    ...mapGetters(["getMainColor", "getAccentColor"]),
  },
};
</script>

<style lang="less" scoped>
.content-title {
  color: #ffffff;
  font-size: 2.5vh;
  text-align: center;
  font-weight: bold;
}
.faq-return {
  margin-top: 1vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 13px;
  background: #ffab00;
  height: 6vh;
  border-radius: 30px;
  border: 2px solid #ffab00;

  .faq-return-left {
    width: 2vh;
  }
  span {
    margin: 0 auto;
  }
}
.faq-title {
  color: #ffffff;
  line-height: 1.8vh;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.faq-content {
  padding: 1vh 1vh;
  color: white;
  overflow: hidden;
  height: 52vh;
  margin-top: 2vh;
  text-align: center;
  border: 2px solid #ffab00;
  border-radius: 14px;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;

  .van-swipe {
  }

  .faq-title {
    margin: 16px 0;
    font-size: 22px;
    line-height: 22px;
  }

  .indicator {
    display: flex;
    margin-bottom: 16px;
    justify-content: center;

    .indicator-item {
      background-color: white;
      height: 10px;
      width: 10px;
      border-radius: 10px;
      margin: 0 4px;
    }
  }
  .faq-msg {
    text-align: center;
    overflow-y: auto;
    height: 40%;
    font-size: 15px;
    line-height: 15px;
  }
}
</style>